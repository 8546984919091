@import 'assets/scss/variable.module.scss';

.container {
  height: 42px;
  background: var(--brand-01-color);
  padding-left: 12px;
  padding-right: 12px;
  font-size: 14px;
  @media (min-width: 1024px) {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 16px;
  }
  border-radius: 12px;
  font-family: 'Muli-Bold';
  letter-spacing: 0;
  color: var(--text-gray-800-color);

  &:hover {
    opacity: 0.8;
  }
  transition: opacity 0.2s;
  transition-timing-function: ease-in-out;
}
