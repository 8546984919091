@import 'assets/scss/variable.module.scss';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  @media (min-width: 1024px) {
    width: 40px;
    height: 40px;
  }
  border-radius: 12px;
  background-color: var(--background-dark-color);
  border: 1px solid transparent;
  &:hover {
    @media (min-width: 1024px) {
      opacity: 0.8;
    }
  }
  font-family: 'Muli-Bold';
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0;
  color: var(--text-gray-800-color);
  transition: opacity 0.2s;
  transition-timing-function: ease-in-out;
  &:focus {
    outline: none;
  }
}

.image {
  width: 46px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
}
