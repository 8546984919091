@import 'assets/scss/variable.module.scss';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  background: transparent;
  border: 1px solid var(--follow-btn-border-mobile);
  font-size: 18px;
  padding-left: 8px;
  padding-right: 8px;
  @media (min-width: 1024px) {
    font-size: 18px;
    height: 50px;
    padding-left: 12px;
    padding-right: 12px;
  }
  @media (min-width: 1536px) {
    font-size: 18px;
    height: 53px;
    padding-left: 16px;
    padding-right: 16px;
  }
  border-radius: 12px;
  font-weight: 100;
  font-family: 'Muli-SemiBold';
  line-height: 24px;
  letter-spacing: 0;
  color: rgba(var(--text-gray-200-color-rgb), 0.8);


  transition: opacity 0.2s;
  transition-timing-function: ease-in-out;
  outline: none;
}

.hover {
  &:hover {
    @media (min-width: 1024px) {
      color: rgba(var(--text-gray-200-color-rgb), 1) !important;
    }
  }
}