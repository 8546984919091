@import 'assets/scss/variable.module.scss';

.container {
  position: relative;
  z-index: 10000;
}

.bgView {
  position: fixed;
  inset: 0;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  background-color: rgba(#1a1a1a, 0.6);
}

.modalView {
  position: relative;
  width: 343px;
  height: 343px;
  padding-top: 0px;
  padding-bottom: 0px;
  border-radius: 16px;
  @media (min-width: 1024px) {
    width: 750px;
    height: 750px;
    padding-top: 35px;
    padding-bottom: 24px;
    border-radius: 24px;
  }
  background-color: #242424;

  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.title {
  font-family: 'Muli-SemiBold';
  font-size: 16px;
  @media (min-width: 1024px) {
    font-size: 35px;
  }
}

.cropContainer {
  position: relative;
  width: 100%;
  height: 258px;
  @media (min-width: 1024px) {
    height: 560px;
  }
  background-color: #333;
}

.text {
  font-family: 'Muli-SemiBold';
  font-size: 16px;
  @media (min-width: 1024px) {
    font-size: 20px;
  }
}
