@import 'assets/scss/variable.module.scss';

.menuItem {
  width: 100%;
  font-family: 'Muli-Regular';
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0;
  color: rgba(var(--main-bg-color-rgb), 0.7);
  padding: 8px;
  cursor: pointer;
  border-radius: 12px;
  // display: flex;
  // justify-content: flex-start;
  // align-items: center;
  white-space: nowrap;
  border: 1px solid transparent;
  &:hover {
    color: var(--main-icon-color);
  }
  &:hover .iconStroke {
    stroke: var(--main-icon-color);
  }
  &:hover .iconFill {
    fill: var(--main-icon-color);
  }
  &:hover .profileIconFill {
    fill: var(--main-icon-color);
  }
  .iconStroke {
    overflow: visible;
    stroke: var(--main-bg-color);
  }
  .iconFill {
    margin-top: 3px;
    transform: scale(0.7);
    overflow: visible;
    fill: var(--main-bg-color) !important;
  }
  .profileIconFill {
    margin-top: 3px;
    fill: var(--main-bg-color) !important;
  }
}

.border {
  width: 100%;
  height: 1px;
  background: rgba(var(--main-bg-color-rgb), 0.1);
  margin-top: 20px;
}

.imageArea {
  width: 100%;
  height: 100%;
  border-radius: 14px;
  border: 2px solid var(--brand-09-color);
}

.username {
  display: block;
  font-family: 'Muli-Regular';
  font-size: 18px;
  line-height: 22px;
  font-weight: 100;
  color: var(--text-gray-200-color);
  // &:hover {
  //   color: var(--brand-09-color);
  // }
}
