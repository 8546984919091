@import 'assets/scss/variable.module.scss';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  background: linear-gradient(to left, rgba(var(--brand-09-color), 1), rgba(var(--brand-09-color), 1));
  border-radius: 12px;
  font-family: 'Muli-Bold';
  line-height: 100%;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 14px;
  @media (min-width: 1024px) {
    height: 50px;
    font-size: 16px;
    padding-left: 12px;
    padding-right: 12px;
  }
  @media (min-width: 1536px) {
    height: 53px;
    font-size: 18px;
    padding-left: 16px;
    padding-right: 16px;
  }

  letter-spacing: 0;
  color: var(--text-gray-800-color);

  &:hover {
    @media (min-width: 1024px) {
      box-shadow: 0px 3px 6px rgba(var(--brand-06-color), 1);
    }
  }

  transition: box-shadow 0.2s;
  transition-timing-function: ease-in-out;
}
