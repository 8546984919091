@import 'assets/scss/variable.module.scss';

.container {
  width: 100%;
  height: 49px;
  border-radius: 12px;
  background: var(--connect-wallet-color);
  box-shadow: 0px 3px 6px rgba(18, 18, 18, 0.4);

  gap: 13px;

  font-family: 'Muli-Bold';
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0;
  color: var(--text-gray-200-color);

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    @media (min-width: 1024px) {
      color: var(--wallet-btn-hover);
    }
  }
}
@media (min-width: 1025px) {
  .container {
    width: 220px;
    min-width: 220px;
    max-width: 220px;
  }
}

.connectedContainer {
  gap: 13px;
  font-family: 'Muli-Bold';
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0;
  color: var(--text-gray-200-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menuBlur {
  width: 478px;
  height: auto;
  position: absolute;
  right: 0;
  margin-top: 28px;
  transform-origin: top right;
  background-color: var(--bg-1E1E1E-F3F2F0);
  border-radius: 10px;
  box-shadow: 0px 3px 10px rgba(#000, 0.16);
}

.menuArea {
  background: transparent;
  border: 1px solid rgba(var(--text-gray-200-color-rgb), 0.25);
  border-radius: 12px;
  padding: 15px;
}

.title {
  font-family: 'Muli-Bold';
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0;
  color: var(--text-gray-200-color);
}

.errorMessage {
  font-family: 'Muli-Bold';
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0;
  color: $brand-06-color;
}

.priceText {
  font-family: 'Muli-Bold';
  font-size: 16px;
  line-height: 20px;
  font-weight: 100;
  letter-spacing: 0;
  color: var(--text-gray-200-color);
}

.grayText {
  font-family: 'Muli-Bold';
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0;
  color: rgba(var(--text-gray-200-color-rgb), 0.48);
}

.divider {
  width: 100%;
  height: 1px;
  background-color: rgba(var(--text-gray-200-color-rgb), 0.2);
  margin-top: 10px;
  margin-bottom: 10px;
}

.dividerVertical {
  width: 1px;
  height: 14px;
  background-color: rgba(var(--text-gray-200-color-rgb), 0.2);
  margin-top: 10px;
  margin-bottom: 10px;
}

.cardTypeText {
  font-family: 'Muli-SemiBold';
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0;
  color: var(--text-gray-200-color);
  cursor: pointer;
}

.whiteText {
  font-family: 'Muli-SemiBold';
  font-size: 16px;
  line-height: 20px;
  font-weight: 100;
  letter-spacing: 0;
  color: var(--text-gray-200-color);
}

.grayCard {
  width: auto;
  height: 21px;
  border-radius: 6px;
  background: rgba(var(--main-bg-color-rgb), 0.05);

  padding-left: 5px;
  padding-right: 5px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.subText {
  font-family: 'Muli-Regular';
  font-size: 16px;
  line-height: 24px;
  color: rgba(var(--text-gray-200-color-rgb), 0.8);
}

.maxText {
  position: absolute;
  right: 10px;
  padding: 4px;
  top: 8px;
  font-family: 'Muli-Regular';
  font-size: 14px;
  line-height: 24px;
  color: rgba(var(--text-gray-200-color), 0.8);
  cursor: pointer;
}

.viewActiveOffer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-family: 'Muli-SemiBold';
  font-size: 14px;
  line-height: 36px;
  color: var(--main-icon-color);
  cursor: pointer;
}

.cardGrayText {
  font-family: 'Muli-Regular';
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0;
  color: var(--main-icon-color);
  cursor: pointer;
}

.cardWhiteText {
  font-family: 'Muli-Regular';
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0;
  color: rgba(var(--text-gray-200-color-rgb), 0.8);
  cursor: pointer;
}

.greenText {
  font-family: 'Muli-Regular';
  font-size: 9px;
  line-height: 11px;
  letter-spacing: 0;
  color: rgba(var(--text-gray-200-color-rgb), 0.8);
}

.walletCard {
  width: 100%;
  border-radius: 12px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.depositWithdraw {
  border-radius: 16px;
}

.iconArea {
  width: 46px;
  aspect-ratio: 1/1;
  margin-right: 30px;
  border-radius: 50px;
  background-color: rgba(var(--main-bg-color-rgb), 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
  * {
    fill: var(--main-bg-color);
  }
}
