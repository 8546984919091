@import 'assets/scss/variable.module.scss';

.container {
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  background: var(--bg-95dbd505-202427);
  border: 1px solid var(--bg-4D6A67-202427);
  transition: box-shadow 0.2s;
  transition-timing-function: ease-in-out;
  font-family: 'Muli-Regular';
  font-size: 16px;
  font-weight: 100;
  color: #eff0f6;
}
