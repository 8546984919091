@import 'assets/scss/variable.module.scss';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  font-family: 'Muli-Bold';
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0;
  color: var(--text-gray-200-color);
}

.menuArea {
  position: fixed;
  top: 105px;
  right: 180px;
  width: 627px;
  height: 609px;
  padding: 8px;
  background-color: var(--bg-1E1E1E-F3F2F0);
  border-radius: 10px;
  border: 1px solid rgba(var(--text-gray-200-color-rgb), 0.25);
  box-shadow: 0px 3px 10px rgba(#000, 0.16);
  color: var(--text-gray-200-color);
  cursor: pointer;
}

.menuItem {
  font-family: 'Muli-SemiBold';
  font-size: 18px;
}

.title {
  font-family: 'Muli-Bold';
  font-size: 24px;
  color: var(--text-gray-200-color);
}

.noTitle {
  font-family: 'Muli-SemiBold';
  font-size: 18px; 
  color: var(--text-gray-200-color);
  text-align: center;
}

.noDesc {
  font-family: 'Muli-SemiBold';
  font-size: 14px; 
  color: var(--text-gray-200-color);
  text-align: center;
  margin-top: 30px;
}

.iconArea {
  width: 46px;
  margin-right: 30px;
  border-radius: 50px;
  background-color: rgba(var(--main-bg-color-rgb), 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
  * {
    fill: var(--main-bg-color);
  }
  aspect-ratio: 1;
}

