@import 'assets/scss/variable.module.scss';

.container {
  position: relative;
  z-index: 10002;
}

.bgView {
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  background-color: rgba(#1a1a1a, 0.6);
  position: fixed;
  inset: 0;
}

.modalView {
  position: relative;
  overflow: hidden;
  width: 488px;
  height: 100%;
  // background-origin: border-box;
  // border-radius: 16px;
  @media (min-width: 1024px) {
    // background: var(--profile-setup-bg);
    // padding: 35px 25px;
    // border-radius: 30px;
    // border: 1px solid rgba(239, 240, 247, 0.1);
  }
}

.close {
  position: absolute;
  top: 20px;
  right: 24px;
  @media (min-width: 1024px) {
    right: 16px;
  }
  cursor: pointer;
  z-index: 20;
}

.leftPanel {
  width: 100%;
  height: 100%;
  background: #242424;
  padding: 33px 22px;
  @media (min-width: 1024px) {
    padding: 31px 41px;
    border-radius: 21px;
  }
  text-align: center;
  border-radius: 16px;
  // box-shadow: 0px 13px 36px rgba(#061a1c, 0.5);
}

.displayName {
  font-family: 'Muli-Regular';
  font-size: 14px;
  color: $text-gray-200-color;
  line-height: 18px;
  text-align: start;
  padding: 11px 0px;
}

.title {
  font-family: 'Muli-Bold';
  font-size: 18px;
  @media (min-width: 1024px) {
    font-size: 24px;
  }
  color: $text-gray-200-color;
  line-height: 44px;
}

.text {
  font-family: 'Muli-Light';
  font-size: 16px;
  line-height: 20px;
  margin-top: 5px;
  @media (min-width: 1024px) {
    margin-top: 20px;
  }
  color: rgba(#eff0f6, 0.9);
}

.avatarArea {
  position: relative;
  width: 105px;
  height: 105px;
  @media (min-width: 1024px) {
    width: 170px;
    height: 170px;
  }
  border-radius: 100%;
  background: rgba(239, 240, 247, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.cameraArea {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  @media (min-width: 1024px) {
    width: 50px;
    height: 50px;
    border-radius: 25px;
  }
  background: rgba(#eff0f6, 0.25);
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
}

.rightBack {
  position: absolute;
  bottom: -180px;
  right: -126px;
  z-index: 0;
}

.rightPanel {
  position: absolute;
  right: -20px;
  bottom: -40px;
}

.rightTitle {
  width: 230px;
  margin-top: 36px;
  font-family: 'KCDurhamboxcar';
  font-size: 53px;
  color: var(--text-gray-200-color);
  line-height: 36px;
}
