@import 'assets/scss/variable.module.scss';

.container {
  width: 100%;
  height: 80px;
  padding-left: 16px;
  padding-right: 8px;
  @media (min-width: 1024px) {
    width: auto;
    height: 50px;
    border-radius: 12px;
    padding-left: 10px;
    padding-right: 15px;
  }
  // @media (min-width: 1150px) {
  //   max-width: 300px;
  //   margin-left: 30px
  // }
  // @media (min-width: 1300px) {
  //   max-width: 350px;
  //   margin-left: 40px
  // }
  // @media (min-width: 1440px) {
  //   max-width: 500px;
  // }
  // @media (min-width: 1680px) {
  //   max-width: 500px;
  // }
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.borderLine {
  @media (min-width: 1024px) {
    width: 100%;
    border: 2px solid var(--main-icon-color);
    background: rgba(var(--main-bg-color-rgb), 0.05);
  }
}

.noResult {
  width: 100%;
  display: flex;
  justify-content: center;
  font-family: 'Muli-Regular';
  font-weight: 100;
  font-size: 18px;
  line-height: 24px;
  color: var(--text-gray-200-color);
}

.searchArea {
  background: rgba(var(--main-bg-color-rgb), 0.05);
  border-radius: 12px;
  min-width: 100px;
  display: flex;
  flex: 1;
  @media (min-width: 1024px) {
    background: transparent;
  }
}

.inputArea {
  flex: 1;
  width: 100%;
  height: 40px;
  padding-left: 9px;
  padding-right: 12px;
  padding-bottom: 4px;
  font-family: 'Muli-Light';
  font-weight: 100;
  font-size: 16px;
  line-height: 24px;
  color: var(--text-gray-200-color);
  background: transparent;
  @media (min-width: 1024px) {
    background-color: transparent;
  }
  outline: none;
}

.inputArea::placeholder {
  color: rgba(var(--text-gray-200-color-rgb), 1);
  font-family: 'Muli-Light';
  font-weight: 100;
  font-size: 16px;
  line-height: 24px;
  @media (min-width: 1024px) {
    font-size: 16px;
    line-height: 24px;
  }
}

.iconArea {
  height: 40px;
  padding-left: 10px;
  padding-right: 0px;

  @media (min-width: 1024px) {
    padding-left: 6px;
    background-color: transparent;
  }
  display: flex;
  justify-content: center;
  align-items: center;
}

.contentArea {
  position: absolute;
  top: 80px;
  left: 0px;
  right: 0px;
  height: 100vh;
  overflow-y: auto;
  background-color: transparent;
  @media (min-width: 1024px) {
    top: 75px;
    left: 0px;
    height: auto;
    max-width: 800px;
    min-width: 560px;
    width: 100%;
    max-height: 800px;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    background-color: var(--bg-1E1E1E-F3F2F0);
    border-radius: 15px;
    border: 1px solid rgba(var(--text-gray-200-color-rgb), 0.25);
  }
  padding: 20px 16px;
  box-shadow: 0px 3px 10px rgba(#000, 0.16);
}

.title {
  font-family: 'Muli-Regular';
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: var(--text-gray-200-color);
  @media (min-width: 1024px) {
    color: var(--search-category-color);
  }
}

.text {
  font-family: 'Muli-Regular';
  font-size: 14px;
  line-height: 18px;
  @media (min-width: 1024px) {
    font-size: 16px;
    line-height: 22px;
  }
  color: var(--text-gray-200-color);
  flex: 1 auto;
}

.searchDetailIcon {
  padding-left: 12px;
  padding-right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.searchTopic {
  font-family: 'Muli-Light';
  font-size: 12px;
  line-height: 15px;
  color: rgba(var(--text-gray-200-color-rgb), 0.75);
}

.username {
  font-family: 'Muli-Light';
  font-size: 14px;
  line-height: 18px;
  @media (min-width: 1024px) {
    font-size: 16px;
    line-height: 22px;
  }
  color: rgba(var(--text-gray-200-color-rgb), 0.75);
  flex: 1 auto;
}

.selection {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  padding: 8px 8px;
  color: var(--text-gray-200-color);

  &:hover {
    @media (min-width: 1024px) {
      background: var(--profile-menu-item-bg);
      border-radius: 8px;
    }
  }
}
