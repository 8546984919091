@import 'assets/scss/variable.module.scss';

.container {
  position: relative;
  z-index: 10002;
}

.bgView {
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  background-color: rgba(#1a1a1a, 0.6);  position: fixed;
  inset: 0;
}

.modalView {
  width: 960px;
  height: 640px;
  // background-color: var(--brand-01-color);
  background: linear-gradient(to bottom, rgba(var(--text-gray-800-color), 1), rgba(var(--brand-01-color), 1));
  border: 1px solid var(--brand-07-color);
  border-radius: 30px;
}

.leftPanel {
  width: 100%;
  height: 100%;
  padding: 30px 30px 0px 30px;
  text-align: left;
  background-color: var(--text-gray-800-color);
  border-radius: 21px;
  border: 1px solid var(--brand-07-color);
}

.title {
  font-family: 'Muli-Bold';
  font-size: 32px;
  line-height: 44px;
  // margin-top: 36px;
}

.content {
  font-family: 'Muli-Regular';
  font-size: 16px;
  line-height: 24px;
  margin-top: 25px;
}

.contentBold {
  font-family: 'Muli-Bold';
  font-size: 16px;
  line-height: 24px;
}

.rightBack {
  position: absolute;
  bottom: -180px;
  right: -126px;
}

.close {
  position: absolute;
  top: 20px;
  right: 16px;
  cursor: pointer;
}

.rightPanel {
  position: absolute;
  top: 50px;
  left: 0px;
  right: 0px;
}

.rightTitle {
  width: 230px;
  margin-top: 36px;
  font-family: 'Muli-Bold';
  font-size: 24px;
  line-height: 36px;
}
