@import 'assets/scss/variable.module.scss';

.menu {
  width: 100vw;
  min-height: 100vh;
  height: 100vh;
  background-color: var(--menu-bg-color);
  z-index: 100;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;

  position: fixed;
  // top: 0;
  // left: -100vw;
  top: 80px;
  left: 0;
  bottom: 0;
  z-index: 120;
  overflow: hidden;
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  padding: 15px 15px 95px 15px;

  overflow: auto;
  width: 100%;
  height: 100%;
  position: relative;
}

.bgImage {
  position: absolute;
  top: 10px;
  left: -200px;
  opacity: 0.4;
  width: 549px;
  height: 1005px;
  z-index: -1;
}

.logout {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  width: 100%;
  background: transparent;
  border: 1px solid rgba(var(--text-gray-200-color-rgb), 0.4);
  font-size: 14px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 12px;
  font-family: Muli-Bold;
  line-height: 24px;
  letter-spacing: 0;
  color: var(--text-gray-200-color);

  &:not([disabled]):hover {
    background-color: transparent;
    opacity: 0.8;
  }
}

.separator {
  width: 100%;
  height: 1px;
  margin-top: 30px;
  background: rgba(var(--text-gray-200-color-rgb), 0.1);
}

.footerText {
  color: var(--text-gray-200-color);
  font-family: 'Muli-Light';
  font-weight: 100;
  font-size: 16px;
  margin-top: 9px;
  cursor: pointer;
}

.allRights {
  color: rgba(var(--text-gray-200-color-rgb), 0.6);
  font-family: 'Muli-Light';
  font-weight: 100;
  font-size: 16px;
  line-height: 20px;
  margin-top: 15px;
}

.signIn {
  font-family: 'Muli-SemiBold';
  font-size: 18px;
  color: var(--text-gray-200-color);
  cursor: pointer;
}

.signBorder {
  width: 100%;
  height: 1px;
  background: rgba(var(--main-bg-color-rgb), 0.1);
}

.navBorder {
  width: calc(100% + 32px);
  height: 1px;
  background: rgba(var(--main-bg-color-rgb), 0.1);
}

.colorMode {
  color: var(--text-gray-200-color);
  font-family: 'Muli-SemiBold';
  font-weight: 100;
  font-size: 16px;
  line-height: 20px;
}