@import 'assets/scss/variable.module.scss';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 53px;
  // border-radius: 15px;
  // padding-left: 25px;
  // padding-right: 25px;
  // background: var(--menu-item-bg);
  // border: 1px solid var(--menu-item-border);
  // box-shadow: 0px 0px 10px rgba(#202427, 0.15);
  &:focus {
    outline: none;
  }
}

.bottomBorder {
  border-bottom: 1px solid rgba(#707070, 0.25);
}

.openContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 53px;
  // border-radius: 15px;
  // padding-left: 25px;
  // padding-right: 25px;
  // background: var(--menu-item-open-bg);
  // border: 1px solid rgba(var(--main-bg-color-rgb), 0.1);
  // background-origin: border-box;
  &:focus {
    outline: none;
  }
}

.label {
  font-family: 'Muli-SemiBold';
  font-weight: 100;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0;
  color: var(--text-gray-200-color);
}

.openLabel {
  
  font-family: 'Muli-SemiBold';
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
  color: var(--menu-item-open-color);
}

.menuItem {
  font-family: 'Muli-Regular';
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0;
  color: rgba(var(--text-gray-200-color-rgb), 0.7);
  gap: 6px;
  cursor: pointer;
  border-radius: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
}

.imageArea {
  width: 24px;
  height: 24px;
  border-radius: 14px;
  border: 2px solid var(--brand-09-color);
}

.rightIcon {
  color: var(--menu-item-right-icon);
}

.openIconColor {
  color: var(--menu-item-right-open-icon);
}
