@import 'assets/scss/variable.module.scss';

.container {
  width: 100%;
  height: 45px;
  border-radius: 5px;
  // background: var(--search-bg-color);
  background: transparent;
  padding-left: 20px;
  padding-right: 12px;
  font-family: 'Muli-Light';
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
  border-width: 1px;
  color: var(--text-gray-200-color);
  &:focus {
    outline: none;
  }
}

.container::placeholder {
  font-family: 'Muli-Light';
  font-size: 16px;
  @media (min-width: 1024px) {
    font-size: 16px;
  }
  color: rgba(var(--text-gray-200-color-rgb), 0.6);
}

.label {
  margin-bottom: 10px;
  font-family: 'Muli-Regular';
  font-size: 16px;
  line-height: 21px;
  @media (min-width: 1024px) {
    font-size: 18px;
    line-height: 36px;
  }
  color: var(--text-gray-200-color);
  letter-spacing: 0;
  text-align: start;
  display: flex;
  align-items: center;
  gap: 12px;
}

.bottomLabel {
  margin-top: 10px;
  font-family: 'Muli-Light';
  font-size: 14px;
  line-height: 18px;
  @media (min-width: 1024px) {
    font-size: 14px;
    line-height: 18px;
  }
  letter-spacing: 0;
  color: rgba(var(--text-gray-200-color-rgb), 0.6);
}

.error {
  margin-top: 5px;
  font-family: 'Muli-Regular';
  font-size: 12px;
  line-height: 18px;
  @media (min-width: 1024px) {
    font-size: 14px;
    line-height: 21px;
  }
  letter-spacing: 0;
  color: #EF5350;
  text-align: start;
}
