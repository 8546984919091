@import 'assets/scss/variable.module.scss';

.container {
  display: none;
  height: 28px;
  @media (min-width: 1024px) {
    height: 32px;
  }
  justify-items: center;
  align-items: center;
  position: absolute;
  // right: -70px;
  top: 0;
  font-family: 'Muli-Regular';
  font-size: 14px;
  line-height: 24px;
  background: var(--background-main-color);
  border: 1px solid rgba(var(--main-bg-color-rgb), 0.1);
  // margin-left: 40px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 6px;
  color: var(--text-gray-200-color);
}
