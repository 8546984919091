@import 'assets/scss/variable.module.scss';

.container {
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.signIn {
  font-family: 'Muli-SemiBold';
  font-size: 18px;
  color: var(--text-gray-200-color);
  &:hover {
    color: #95dbd5;
  }
}

.avatar {
  width: 46px;
  height: 46px;
  border-radius: 12px;
  border: 2px solid var(--profile-border-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.profileOutline {
  width: 46px;
  height: 46px;
  border-radius: 12px;
  border: 2px solid var(--profile-active-border-color);
  overflow: hidden;
  cursor: pointer;
}

.menuArea {
  position: absolute;
  top: 76px;
  right: 0;
  min-width: 272px;
  padding: 12px;
  background-color: var(--bg-1E1E1E-F3F2F0);
  // backdrop-filter: blur(20px);
  // -webkit-backdrop-filter: blur(20px);
  border-radius: 10px;
  border: 1px solid rgba(var(--text-gray-200-color-rgb), 0.25);
  box-shadow: 0px 3px 10px rgba(#000, 0.16);
}

.menuItem {
  font-family: 'Muli-Bold';
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0;
  color: var(--text-gray-200-color);
  padding: 8px;
  cursor: pointer;
  border-radius: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  border: 1px solid transparent;
  &:hover {
    @media (min-width: 1024px) {
      background-color: var(--profile-menu-item-bg);
      color: #95dbd5;
    }
  }
}

.imageArea {
  width: 24px;
  height: 24px;
  border-radius: 14px;
  border: 2px solid var(--brand-09-color);
}

.username {
  font-family: 'Muli-Bold';
  font-size: 18px;
  text-align: start;
  color: var(--text-gray-200-color);
}

.wallet {
  font-family: 'Muli-Regular';
  font-size: 14px;
  text-align: start;
  color: rgba(var(--text-gray-200-color-rgb), 0.8);
}

.lightModeButton {
  width: 100%;
  font-family: 'Muli-Bold';
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0;
  color: var(--text-gray-200-color);
  padding: 8px;
  cursor: pointer;
  border-radius: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  border: 1px solid transparent;
  &:hover {
    color: var(--brand-09-color);
    @media (min-width: 1024px) {
      background-color: var(--profile-menu-item-bg);
    }
  }
}

.themeContainer {
  font-family: 'Muli-Regular';
  color: var(--text-gray-200-color);
  display: inline-flex;
  position: relative;
  padding: 0.25rem;
  margin-top: 1rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.375rem;
  width: 100%;
  cursor: pointer;
  user-select: none;
}
