@import 'assets/scss/variable.module.scss';

.container {
  width: 100%;
  height: 100%;
  @media (min-width: 1024px) {
    // max-height: 512px;
    padding: 0px 5px 48px 14px;
    // background-color: var(--notification-card-bg);
    // border: 1px solid rgba(#707070, 0.1);
  }
  // border-radius: 20px;
}

.content {
  width: 100%;
  height: 100%;
  @media (min-width: 1024px) {
    height: auto;
    max-height: 500px;
    padding-right: 36px;
    overflow-y: auto;
  }
  display: flex;
  flex-wrap: wrap;

  @media (min-width: 1024px) {
    gap: 46px;
  }
}

.title {
  font-family: 'Muli-SemiBold';
  font-size: 14px;
  line-height: 18px;
  @media (min-width: 1024px) {
    font-size: 16px;
    line-height: 20px;
  }
  color: rgba(var(--text-gray-200-color-rgb), 0.8);
  margin-top: 30px;
}

.mark {
  font-family: 'Muli-SemiBold';
  font-size: 14px;
  line-height: 18px;
  text-decoration: underline;
  color: rgba(var(--text-gray-200-color-rgb), 1);
  margin-top: 20px;
  @media (min-width: 1024px) {
    font-size: 16px;
    line-height: 20px;
  }
  cursor: pointer;
  z-index: 10;
}

.dot {
  display: none;
  @media (min-width: 1024px) {
    display: flex;
  }
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: linear-gradient(to bottom, var(--brand-01-color) 0%, #2d625d 100%);
  margin-right: 14px;
}

.read {
  display: none;
  @media (min-width: 1024px) {
    display: flex;
  }
  width: 8px;
  height: 8px;
  border-radius: 100%;
  margin-right: 14px;
}

.avatarArea {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 41px;
  height: 41px;
  min-width: 41px;
  min-height: 41px;
  margin-right: 10px;
  @media (min-width: 1024px) {
    width: 52px;
    height: 52px;
    min-width: 52px;
    min-height: 52px;
    margin-right: 25px;
  }
  border-radius: 100%;
  // padding: 1px;
  // background: linear-gradient(
  //   110deg,
  //   var(--brand-06-color) -60%,
  //   #5e2028 20%,
  //   var(--brand-01-color) 50%,
  //   var(--brand-04-color) 80%,
  //   var(--brand-01-color) 140%
  // );
}

.username {
  font-family: 'Muli-Bold';
  font-size: 14px;
  line-height: 18px;
  @media (min-width: 1024px) {
    font-size: 16px;
    line-height: 22px;
  }
  color: var(--text-gray-200-color);
  cursor: pointer;
}

.description {
  font-family: 'Muli-Regular';
  font-size: 14px;
  line-height: 18px;
  @media (min-width: 1024px) {
    font-size: 16px;
    line-height: 22px;
  }
  color: var(--text-gray-200-color);
}

.type {
  font-family: 'Muli-Regular';
  font-size: 14px;
  line-height: 18px;
  @media (min-width: 1024px) {
  font-size: 16px;
  line-height: 22px;
  }
  color: var(--text-gray-200-color);
}

.hour {
  font-family: 'Muli-Regular';
  font-size: 15px;
  line-height: 20px;
  color: var(--text-gray-200-color);
  opacity: 0.8;
}

.viewDetails {
  font-family: 'Muli-Regular';
  font-size: 12px;
  line-height: 18px;
  @media (min-width: 1024px) {
    font-size: 16px;
    line-height: 20px;
  }
  color: var(--brand-09-color);
  cursor: pointer;
}

.assetUrl {
  width: 39px;
  height: 39px;
  min-width: 39px;
  min-height: 39px;
  @media (min-width: 1024px) {
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
  }
  overflow: hidden;
  border-radius: 8px;
  cursor: pointer;
}
