@import 'assets/scss/variable.module.scss';

.container {
  width: 100%;
  height: 100%;
  padding: 0px;
  @media (min-width: 640px) {
    padding: 21px 15px 30px 30px;
  }
}

.title {
  font-family: 'Muli-Regular';
  font-size: 18px;
  line-height: 23px;
  color: var(--text-gray-200-color);
  @media (min-width: 640px) {
    font-family: 'Muli-SemiBold';
  }
}

.titleV1 {
  font-family: 'Muli-Light';
  font-size: 14px;
  line-height: 18px;
  color: var(--main-icon-color);
}

.selectAll {
  font-family: 'Muli-Regular';
  font-weight: 100;
  font-size: 16px;
  line-height: 16px;
  @media (min-width: 640px) {
    line-height: 20px;
  }
  color: var(--main-icon-color);
  cursor: pointer;
}

.bottomSection {
  position: fixed;
  left: 0px;
  bottom: 0px;
  display: block;
  width: 100%;
  height: 137px;
  background: var(--background-main-color);
  padding: 20px 16px;
  border: 1px solid rgba(var(--main-bg-color-rgb), 0.1);
  border-radius: 20px 20px 0px 0px;
  @media (min-width: 640px) {
    display: none;
  }
}

.subtotal {
  font-family: 'Muli-Light';
  font-weight: 100;
  font-size: 16px;
  line-height: 20px;
  color: var(--main-bg-color);
  @media (min-width: 640px) {
    display: none;
    color: rgba(var(--main-bg-color-rgb), 0.9);
  }
}

.price {
  font-family: 'Muli-Regular';
  font-weight: 100;
  font-size: 18px;
  line-height: 23px;
  color: var(--main-bg-color);
}

.usd {
  font-family: 'Muli-Light';
  font-weight: 100;
  font-size: 18px;
  line-height: 23px;
  color: var(--main-bg-color);
}

.lootieTitle {
  display: flex;
  justify-content: center;
  font-family: 'Muli-SemiBold';
  font-size: 16px;
  color: var(--text-gray-200-color);
}

.lootieSubTitle {
  display: flex;
  justify-content: center;
  color: rgba(var(--text-gray-200-color-rgb), 0.8);
  font-family: 'Muli-Light';
  font-size: 14px;
  line-height: 18px;
  margin-top: 9px;
}