@import 'assets/scss/variable.module.scss';

@button-tint: fadeout(#000, 90%);

.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  border-radius: 10px;
  font-family: 'Muli-SemiBold'; 
  font-size: 18px;
  line-height: 23px;
  font-weight: 100;
  letter-spacing: 0;
  color: rgba(var(--bg-1A1A1A-FFF-RGB), 0.8);
  transition: box-shadow 0.2s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  background: var(--main-icon-color);
}

.disabled {
  color: rgba(var(--bg-1A1A1A-FFF-RGB), 0.5);
}

.hoverBtn {
  color: rgba(var(--bg-1A1A1A-FFF-RGB), 1);
}

.iconArea {
	&:hover {
		-webkit-animation: bounceright .3s alternate ease infinite;
		animation: bounceright .3s alternate ease infinite;
	}
}

.iconBG {
  background: rgba(var(--background-main-color-rgb), 0.1);
}

@keyframes bounceright {
	from { transform: translateX(0); }
	to   { transform: translateX(5px); }
}