@import 'assets/scss/variable.module.scss';

.menu {
  width: 100vw;
  min-height: 100vh;
  height: 100%;
  background: var(--mobile-serachbox-bg); //rgba(var(--background-dark-color), 0.6);
  // -webkit-backdrop-filter: blur(12px);
  // backdrop-filter: blur(12px);
  z-index: 100;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;

  position: fixed;
  // top: 0;
  // left: -100vw;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 10000;
  overflow: hidden;
}

.menuOpen {
  width: 100vw;
  min-height: 100vh;
  background-color: #0f1316;
  z-index: 100;
  padding: 15px 15px 92px 15px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;

  position: fixed;
  top: 0;
  left: 0;
  // animation-name: menu_open;
  // animation-duration: 0.8s;
  // animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  // animation-direction: alternate;
  // overflow: hidden;
}

.menuClose {
  width: 100vw;
  min-height: 100vh;
  background-color: #0f1316;
  z-index: 100;
  padding: 15px 15px 92px 15px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;

  position: fixed;
  top: 0;
  left: 0;
  // animation-name: menu_close;
  // animation-duration: 0.8s;
  // animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  // animation-direction: alternate;
  // animation-fill-mode: forwards;
  // overflow: hidden;
}

@keyframes menu_open {
  from {
    transform: translateX(-100vw);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes menu_close {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100vw);
  }
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  padding: 15px 15px 92px 15px;

  overflow: auto;
  width: 100%;
  height: 100%;
}
