@import 'assets/scss/variable.module.scss';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  font-family: 'Muli-Regular';
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0;
  color: var(--text-gray-200-color);
  &:hover {
    color: #95dbd5;
  }
}

.menuArea {
  position: fixed;
  top: 105px;
  width: auto;
  height: 360px;
  padding: 8px;
  background-color: var(--bg-1E1E1E-F3F2F0);
  // backdrop-filter: blur(30px);
  // -webkit-backdrop-filter: blur(30px);
  border-radius: 10px;
  border: 1px solid rgba(var(--text-gray-200-color-rgb), 0.25);
  color: var(--text-gray-200-color);
  box-shadow: 0px 3px 10px rgba(#000, 0.16);
  cursor: pointer;
}

.menuItem {
  font-family: 'Muli-SemiBold';
  font-size: 16px;
  font-weight: 100;
}

.title {
  font-family: 'Muli-SemiBold';
  font-size: 18px;
  font-weight: 100;
  &:hover {
    opacity: 0.7;
  }
}

.text {
  font-family: 'Muli-Regular' !important;
  font-size: 16px !important;
  color: rgba(var(--text-gray-200-color-rgb), 0.8);
  &:hover {
    color: var(--main-icon-color);
  }
}
