@import 'assets/scss/variable.module.scss';

.blurContainer {
  width: 100%;
  height: 100px;
  position: relative;
  background: var(--nav-bg-color);
  // border-bottom: 1px solid var(--header-bottom-color);
  // box-shadow: 0px 0px 6px var(--nav-bg-shadow);
  
  .linkNames * {
    font-family: 'Muli-SemiBold';
    font-size: 18px;
    font-weight: 400;
    opacity: 1;
  }
}
.blurContainer::before {
  backdrop-filter: blur(20px);
  content: '';
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

.mobileBlurContainer {
  position: fixed;
  width: 100%;
  height: 80px;
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  background: var(--nav-bg-color);
  z-index: 9999;
}

.mobileNonBlurContainer {
  position: fixed;
  width: 100%;
  height: 80px;
  background: var(--nav-bg-color);
  z-index: 9999;
}

.mobileContainer {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  background: var(--nav-bg-color);
  z-index: 10000;
}

.heroLogoArea {
  width: 176px;
  min-width: 176px;
  cursor: pointer;
}

.logoArea {
  margin-left: -80px;
  @media (min-width: 1536px) {
    margin-left: -110px;
  }
  @media (min-width: 1920px) {
    margin-left: -130px;
  }
  width: 80px;
  min-width: 80px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.cartCount {
  width: 16px;
  height: 16px;
  font-size: 12px;
  @media (min-width: 1024px) {
    width: 20px;
    height: 20px;
    font-size: 14px;
  }
  border-radius: 100%;
  background: #95dbd5;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Muli-Bold';
  color: #202427;
}

.alphaArea {
  width: 98px;
  height: 36px;
  margin-left: 14px;
  background: var(--alpha-area-bg);
  @media (min-width: 1024px) {
    width: 140px;
    min-width: 140px;
    height: 47px;
    margin-left: 28px;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--alpha-area-border);
  border-radius: 12px;
}

.lightDarkMode {
  margin-top: 70px;
  @media (min-width: 1024px) {
    margin-top: 60px;
  }
}

.tooltip {
  background: rgba(var(--background-light-color), 0.2);
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  border-radius: 8px;
  font-family: 'Muli-Regular';
  font-size: 14px;
  line-height: 18px;
  padding: 16px;
  color: white;
}

.divider {
  position: absolute;
  width: 100%;
  height: 1px;
  top: 79px;
  left: 0px;
  right: 0px;
  background: var(--nav-divider-color);
}

.studioMenu {
  color: var(--text-gray-200-color);
  opacity: 1;
  font-size: 18px;
  line-height: 24px;
  font-family: 'Muli-Bold';
  cursor: pointer;
  &:hover {
    color: #95dbd5;
  }
}

.iconArea {
  width: 46px;
  aspect-ratio: 1/1;
  margin-right: 20px;
  border-radius: 50px;
  background-color: rgba(var(--main-bg-color-rgb), 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
  * {
    fill: var(--main-bg-color);
  }
}

.logoIcon {
  scale: 1;
  overflow: visible;
  fill: var(--main-icon-color);
}

.logoFont {
  font-family: "Ibrand" !important;
  font-size: 37px !important;
  margin-top: 6px;
  color: var(--text-gray-200-color)
}
