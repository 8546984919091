@import 'assets/scss/variable.module.scss';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 53px;
  // border-radius: 15px;
  // padding-left: 25px;
  // padding-right: 25px;
  // background: var(--menu-item-bg);
  // box-shadow: 0px 0px 10px rgba(#202427, 0.15);
  // border: 1px solid var(--menu-item-border);
  // border-color: rgba($background-light-color, 0.1);
  // border-width: 1px;
  &:focus {
    outline: none;
  }
}

.bottomBorder {
  border-bottom: 1px solid rgba(#707070, 0.25);
}

.openContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 71px;
  border-radius: 15px;
  padding-left: 25px;
  padding-right: 25px;
  background: var(--menu-item-open-bg);
  border: 1px solid rgba(var(--main-bg-color-rgb), 0.1);
  background-origin: border-box;
  &:focus {
    outline: none;
  }
}

.label {
  font-family: 'Muli-SemiBold';
  font-weight: 100;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0;
  color: var(--text-gray-200-color);
}

.openLabel {
  margin-left: 20px;
  font-family: 'Muli-SemiBold';
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
  color: var(--menu-item-open-color);
}

.title {
  font-family: 'Muli-Bold';
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0;
  color: var(--text-gray-200-color);
}

.errorMessage {
  font-family: 'Muli-Bold';
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0;
  color: $brand-06-color;
}

.grayText {
  font-family: 'Muli-Bold';
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0;
  color: rgba(var(--text-gray-200-color-rgb), 0.48);
}

.priceText {
  font-family: 'Muli-Bold';
  font-size: 16px;
  line-height: 20px;
  font-weight: 100;
  letter-spacing: 0;
  color: var(--text-gray-200-color);
}

.subText {
  font-family: 'Muli-Light';
  font-size: 16px;
  line-height: 20px;
  color: rgba(var(--text-gray-200-color-rgb), 0.8);
}

.divider {
  width: 100%;
  height: 1px;
  background-color: rgba(var(--text-gray-200-color-rgb), 0.2);
  margin-top: 10px;
  margin-bottom: 10px;
}

.whiteText {
  font-family: 'Muli-SemiBold';
  font-size: 16px;
  line-height: 20px;
  font-weight: 100;
  letter-spacing: 0;
  color: var(--text-gray-200-color);
}

.grayCard {
  width: auto;
  height: 21px;
  border-radius: 6px;
  background: rgba(var(--main-bg-color-rgb), 0.05);

  padding-left: 6px;
  padding-right: 6px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.divider {
  width: 1px;
  height: 14px;
  background: rgba(var(--text-gray-200-color-rgb), 0.25);
}

.cardGrayText {
  font-family: 'Muli-Regular';
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0;
  color: var(--main-icon-color);
}

.cardWhiteText {
  font-family: 'Muli-Regular';
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0;
  color: rgba(var(--text-gray-200-color-rgb), 0.8);
}

.cardTypeText {
  font-family: 'Muli-SemiBold';
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0;
  color: var(--text-gray-200-color);
}

.greenText {
  font-family: 'Muli-Regular';
  font-size: 9px;
  line-height: 11px;
  letter-spacing: 0;
  color: rgba(var(--text-gray-200-color-rgb), 0.8);
}

.walletCard {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 8px 0px;
}

.depositWithdraw {
  // background: var(--social-bg-color);
  border-radius: 12px;
}

.maxText {
  position: absolute;
  right: 10px;
  padding: 4px;
  top: 8px;
  font-family: 'Muli-Regular';
  font-size: 12px;
  line-height: 15px;
  color: rgba(var(--text-gray-200-color), 0.8);
  cursor: pointer;
}

.viewActiveOffer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-family: 'Muli-SemiBold';
  font-size: 12px;
  line-height: 36px;
  color: var(--main-icon-color);
  cursor: pointer;
}

.rightIcon {
  color: var(--menu-item-right-icon);
}

.openRightIcon {
  color: #95dbd5;
}
