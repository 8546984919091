@import 'assets/scss/variable.module.scss';

.container {
  position: relative;
  z-index: 10002;
}

.bgView {
  position: fixed;
  border-radius: 30px;
  background: var(--background-main-color);
  border: 1px solid rgba(var(--main-bg-color-rgb), 0.1);
  left: 45px;
  bottom: 62px;
  width: 100%;
  max-width: 620px;
  box-shadow: 0px 0px 10px var(--notification-shadow-color);
}

.closeBG {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 30px;
  @media (min-width: 1024px) {
    width: 41px;
    height: 41px;
    border-radius: 30px;
    top: -10px;
    right: 20px;
  }
  background: var(--selected-tab-color);
  cursor: pointer;
}

.modalView {
  width: 100%;
  height: 100%;
  background-color: var(--background-dark-color);
  border: 1px solid #22777b;
  border-radius: 15px;
  padding: 5px 15px 13px 15px;
  display: block;
}

.errorModalView {
  width: 100%;
  height: 100%;
  background-color: var(--background-black-color);
  border-radius: 15px;
  padding: 15px 25px 13px 25px;
  display: block;
}

.title {
  font-family: 'Muli-Regular';
  font-size: 18px;
  line-height: 20px;
  @media (min-width: 1024px) {
    font-size: 20px;
    line-height: 24px;
    font-family: 'Muli-SemiBold';
  }
  color: var(--tab-bottom-color);
}

.divider {
  width: 100%;
  height: 1px;
  background-color: var(--secondary-button-border);
  margin-top: 25px;
}

.description {
  margin-left: 40px;
  margin-right: 60px;
  font-family: 'Muli-Regular';
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 24px;
    font-family: 'Muli-Regular';
  }
  color: var(--text-gray-200-color);
}

.errorDescription {
  font-family: 'Muli-SemiBold';
  font-size: 12px;
  line-height: 36px;
  @media (min-width: 768px) {
    font-size: 18px;
    line-height: 24px;
  }
  color: $text-gray-200-color;
  background: linear-gradient(
    to bottom,
    rgba(var($background-dark-color), 100%),
    rgba(var($background-black-color), 100%)
  );
  border-radius: 12px;
  padding: 20px 33px;
}

// mobile view
.mobilebgView {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: var(--background-main-color);
  border-radius: 30px 30px 0px 0px;
  border: 1px solid rgba(var(--main-bg-color-rgb), 0.1);
  box-shadow: 0px 0px 10px var(--notification-shadow-color);
}

.mobileClose {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-gray-200-color);
  font-size: 16px;
  font-family: 'Muli-Regular';
  cursor: pointer;
}
