@import 'assets/scss/variable.module.scss';

.container {
  min-width: 100px;
  width: 100%;
  @media (min-width: 1024px) {
    height: 50px;
  }
  height: 45px;
  border-radius: 10px;
  background: transparent;
  border: 1px solid rgba(var(--main-bg-color-rgb), 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 15px;
  z-index: 10;
}

.inputArea {
  flex: 1;
  min-width: 45px;
  font-family: 'Muli-Regular';
  font-size: 16px;
  line-height: 24px;
  color: var(--text-gray-200-color);
  background-color: transparent;
  outline: none;
}

.inputArea::placeholder {
  font-family: 'Muli-Light';
  font-size: 16px;
  color: rgba(var(--text-gray-200-color-rgb), 0.7);
}

.maxText {
  padding: 4px;
  font-family: 'Muli-Regular';
  font-size: 14px;
  line-height: 24px;
  color: rgba(var(--text-gray-200-color-rgb), 0.8);
  cursor: pointer;
}

.buttonText {
  font-size: 14px;
  font-family: 'Muli-Regular';
  color: var(--place-bid-minimum-color);
}
