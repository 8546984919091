@import 'assets/scss/variable.module.scss';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 53px;
  // border-radius: 15px;
  // padding-left: 25px;
  // padding-right: 25px;
  // background: var(--menu-item-bg);
  // border: 1px solid var(--menu-item-border);
  // box-shadow: 0px 3px 3px rgba(#202427, 0.15);

  &:focus {
    outline: none;
  }
}

.bottomBorder {
  border-bottom: 1px solid rgba(#707070, 0.25);
}

.label {
  font-family: 'Muli-SemiBold';
  font-weight: 100;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0;
  color: var(--text-gray-200-color);
}

.openContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 71px;
  border-radius: 15px;
  padding-left: 25px;
  padding-right: 25px;
  background: var(--menu-item-open-bg);
  border: 1px solid rgba(239, 240, 246, 0.1);
  background-origin: border-box;
  &:focus {
    outline: none;
  }
}

.openLabel {
  margin-left: 20px;
  font-family: 'Muli-SemiBold';
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
  color: var(--menu-item-open-color);
}

.menuItem {
  width: 100%;
  font-family: 'Muli-Regular';
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
  color: rgba(var(--text-gray-200-color-rgb), 0.7);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
}

.imageArea {
  width: 22px;
  height: 22px;
  border-radius: 11px;
  overflow: hidden;
}

.rightIcon {
  color: var(--menu-item-right-icon);
}

.openIconColor {
  color: var(--menu-item-right-open-icon);
}

.iconFill {
  margin-top: 3px;
  transform: scale(0.7);
  overflow: visible;
  fill: var(--main-bg-color) !important;
}

.profileIconFill {
  margin-top: 3px;
  fill: var(--main-bg-color) !important;
}