@import 'assets/scss/variable.module.scss';

.menu {
  width: 100vw;
  height: calc(100vh - 80px);
  background-color: var(--menu-bg-color);
  z-index: 100;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;

  position: fixed;
  // top: 0;
  // left: -100vw;
  top: 80px;
  left: 0;
  bottom: 0;
  z-index: 120;
  overflow: hidden;
}

.container {
  border-top: 1px solid rgba(var(--main-bg-color-rgb), 0.1);
  overflow: auto;
  padding: 15px;
  width: 100%;
  height: 100%;
  position: relative;
}
