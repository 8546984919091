@import 'assets/scss/variable.module.scss';

.container {
  width: 100%;
  height: 100%;
  display: block;
  @media (min-width: 480px) {
    display: block;
  }
}

.nftArea {
  min-width: 120px;
  max-width: 120px;
  height: 183px;
  margin-left: 20px;
  margin-right: 20px;
  @media (min-width: 480px) {
    min-width: 180px;
    max-width: 180px;
    margin-left: 30px;
    margin-right: 30px;
  }
}

.divider {
  width: 100%;
  height: 1px;
  margin-right: 20px;
  background: rgba(var(--text-gray-200-color-rgb), 0.1);
  @media (min-width: 640px) {
    width: calc(100% - 15px);
  }
}

.title {
  font-family: 'Muli-Bold';
  font-size: 30px;
  color: var(--text-gray-200-color);
  padding: 40px 0px 0px 40px;
}
.subTitle {
  font-family: 'Muli-Regular';
  font-size: 16px;
  color: (var(--main-icon-color));
  padding: 0px 7px 30px 40px;
  cursor: pointer;
}
.listContainer {
  max-height: 100vh;
}

.detail {
  font-family: 'Muli-SemiBold';
  font-size: 16px;
  font-weight: 100;
  line-height: 20px;
  color: var(--text-gray-200-color);
}

.cost {
  font-family: 'Muli-SemiBold';
  font-size: 16px;
  font-weight: 100;
  line-height: 20px;
  margin-top: 15px;
  color: var(--text-gray-200-color);
}

.costDivider {
  display: flex;
  width: 1px;
  height: 15px;
  background: rgba(var(--text-gray-200-color-rgb), 0.25);
  align-items: center;
}

.content {
  display: flex;
  flex-direction: column;
  font-family: 'Muli-Regular';
  font-size: 14px;
  margin-top: 18px;
  color: var(--text-gray-200-color);
}

.mobileContent {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 18px;
  font-family: 'Muli-Light';
  color: var(--text-gray-200-color);
}

.remove {
  font-family: 'Muli-Regular';
  font-size: 16px;
  line-height: 20px;
  font-weight: 100;
  color: var(--main-icon-color);
  cursor: pointer;
}

.mobileRemove {
  font-size: 16px;
  font-family: 'Muli-Regular';
  color: var(--main-icon-color);
  cursor: pointer;
}

.edit {
  font-size: 14px;
  font-family: 'Muli-Regular';
  color: var(--main-icon-color);
  cursor: pointer;
}
.mobileEdit {
  font-size: 14px;
  font-family: 'Muli-Regular';
  color: var(--main-icon-color);
  cursor: pointer;
}

.mobileContainer {
  width: 100%;
  height: 100%;
  position: relative;
  display: block;
  margin-bottom: 130px;
  @media (min-width: 480px) {
    margin-top: 20px;
    display: none;
  }
}
.tableColor {
  margin-top: 20px;
  width: 100%;
  border-radius: 15px;
}
